@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/fonts';
@use '$styles/mediaqueries';
@use '$styles/values';
@use '$styles/theme' as *;

$buttonHeight: 64px !default;

@mixin heightAndBorderRadius($height) {
  height: $height;
  border-radius: calc($height/2);
}

.Button {
  font-family: fonts.$headlinesFontFamily;
  display: inline-flex;
  border: solid 2px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;

  &.min180 {
    @include mediaqueries.smallUp {
      min-width: 180px;
    }
  }
}

.center {
  .ButtonContainer {
    justify-content: center;
  }
}

.ButtonContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.ButtonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.S {
  .ButtonLoading {
    scale: 0.8;
  }
}
.XS {
  .ButtonLoading {
    scale: 0.6;
  }
}

.primary {
  $defaultColor: colors.$neutral800;
  $defaultBackgroundColor: colors.$yellow200;
  $defaultBorderColor: colors.$yellow300;
  
  @if ($themeName == 'motrio') {
    $defaultColor: colors.$white;
  }

  color: var(--primary-color-neutral, $defaultColor);
  background-color: var(--primary-color, $defaultBackgroundColor);
  border-color: var(--primary-color, $defaultBorderColor);

  @if ($themeName == 'motrio') {
    &:global(.invert) {
      &:link,
      &:visited,
      &:hover,
      &:active {
        color: colors.$neutral800;
        background-color: colors.$white;
        border-color: colors.$white;
      }

      :global(.ButtonIcon) {
        background-color: colors.$yellow200;
      }
    }
  }

  &:not(.NoShadow) {
    box-shadow: 0px 2px 16px 4px rgba(255, 255, 255, 0.16);
  }

  // @TODO set fonts in global styles

  &:hover {
    $defaultColor: colors.$neutral800;
    $defaultBackgroundColor: colors.$yellow300;
    $defaultBorderColor: colors.$yellow400;

    @if ($themeName == 'motrio') {
      $defaultColor: colors.$white;
    }

    color: var(--primary-color-neutral, $defaultColor);
    background-color: var(--primary-color, $defaultBackgroundColor);
    border-color: var(--primary-color-accent, $defaultBorderColor);
  }

  &:active {
    background-color: var(--primary-color, colors.$yellow400);
  }

  &:empty {
    overflow: hidden;
    border: none;
    padding: 0 !important;
    background-color: transparent;
    pointer-events: none;
    @include values.buttonSkeleton;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    color: colors.$neutral300;
    background-color: colors.$neutral100;
    border-color: colors.$neutral100;

    .ButtonIcon {
      background-color: colors.$neutral200;
    }
  }
  .ButtonIcon {
    color: colors.$white;
  }

  @media (hover: hover) {
    &:not(.fullWidth) {
      transition: margin-right 400ms ease-in-out;

      &.L,
      &.M {
        margin-right: 10px;
      }

      &.S,
      &.XS {
        margin-right: 8px;
      }

      .ButtonIcon {
        order: 2;
      }

      .ButtonContainer::after {
        content: '';
        display: block;
        height: 100%;
        width: 0;
        transition: width 400ms ease-in-out;
      }

      &:hover {
        & {
          margin-right: 0;
          transition: margin-right 400ms ease-in-out;
        }

        .ButtonContainer::after {
          transition: width 400ms ease-in-out;
        }

        &.L,
        &.M {
          .ButtonContainer::after {
            width: 10px;
          }
        }

        &.S,
        &.XS {
          .ButtonContainer::after {
            width: 8px;
          }
        }
      }
    }

    &.fullWidth {
      width: 100%;
      transition: width 400ms ease-in-out;
      &:hover {
        width: 104%;
        transition: width 400ms ease-in-out;
      }
    }
  }

  &.L,
  &.M,
  &.S {
    letter-spacing: -0.01em;
  }

  &.L {
    $padding: 20px;
    $iconContainerSize: 48px;
    $gap: 24px;

    @include heightAndBorderRadius(64px);
    font-size: 18px;
    padding: 0 $padding;
    line-height: 24px;

    > .ButtonContainer {
      column-gap: 24px;
    }

    .ButtonIcon {
      width: $iconContainerSize;
      height: $iconContainerSize;
      border-radius: $iconContainerSize/2;
      margin-right: -(($iconContainerSize - $gap) - ($padding/2));
    }
  }

  &.M,
  &.S,
  &.XS {
    > .ButtonContainer {
      column-gap: 12px;
    }
  }

  &.M {
    $padding: 16px;
    $iconContainerSize: 40px;
    $gap: 12px;

    @include heightAndBorderRadius(52px);
    font-size: 16px;
    padding: 0 $padding;
    line-height: 20px;

    .ButtonIcon {
      width: $iconContainerSize;
      height: $iconContainerSize;
      border-radius: $iconContainerSize/2;
      margin-right: -(($iconContainerSize - $gap) - $padding);
    }
  }

  &.S {
    $padding: 11px;
    $iconContainerSize: 32px;
    $gap: 12px;

    @include heightAndBorderRadius(40px);
    font-size: 14px;
    padding: 0 $padding;
    line-height: 18px;

    .ButtonIcon {
      width: $iconContainerSize;
      height: $iconContainerSize;
      border-radius: $iconContainerSize/2;
      margin-right: -(($iconContainerSize - $gap) - $padding);
    }
  }

  &.XS {
    $padding: 8px;
    $iconContainerSize: 24px;
    $gap: 12px;

    @include heightAndBorderRadius(32px);
    font-size: 12px;
    padding: 0 $padding;
    line-height: 16px;

    .ButtonIcon {
      width: $iconContainerSize;
      height: $iconContainerSize;
      border-radius: $iconContainerSize/2;
      margin-right: -(($iconContainerSize - $gap) - $padding);
    }
  }

  .ButtonIcon {
    background-color: colors.$neutral800;
  }
}

.secondary-dark,
.secondary-light {
  --button-padding-left: 16px;
  --icon-gap: 12px;
  --icon-width: 36px;
  --button-border-width: 2px;
  --icon-side-margin: 8px;

  background-color: transparent;
  border-color: transparent;

  &:empty {
    overflow: hidden;
    border: none;
    padding: 0 !important;
    background-color: transparent;
    pointer-events: none;
    @include values.buttonSkeleton;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    color: colors.$neutral200;
    background-color: colors.$neutral100;
    border-color: colors.$neutral100;

    .ButtonIcon {
      color: colors.$neutral200;
      background-color: transparent;
    }
  }

  &.L,
  &.M,
  &.S {
    letter-spacing: -0.01em;
  }

  &.L {
    --button-padding-left: 16px;
    --icon-width: 36px;
    --icon-gap: 12px;

    @include heightAndBorderRadius(36px);
    font-size: 18px;
    padding: 0 var(--button-padding-left);
    line-height: 24px;

    > .ButtonContainer {
      column-gap: var(--icon-gap);
    }

    .ButtonIcon {
      width: var(--icon-width);
      height: var(--icon-width);
      border-radius: calc(var(--icon-width) / 2);
      margin-right: calc(((var(--icon-width) - var(--icon-gap)) - var(--button-padding-left)) * -1);
    }
  }

  &.M {
    --button-padding-left: 16px;
    --icon-width: 32px;
    --icon-gap: 8px;

    @include heightAndBorderRadius(32px);
    font-size: 16px;
    padding: 0 var(--button-padding-left);
    line-height: 20px;

    > .ButtonContainer {
      column-gap: var(--icon-gap);
    }

    .ButtonIcon {
      width: var(--icon-width);
      height: var(--icon-width);
      border-radius: calc(var(--icon-width) / 2);
      margin-right: calc(((var(--icon-width) - var(--icon-gap)) - var(--button-padding-left)) * -1);
    }
  }

  &.S {
    --button-padding-left: 16px;
    --icon-width: 24px;
    --icon-gap: 8px;
    --icon-side-margin: 4px;

    @include heightAndBorderRadius(24px);
    font-size: 14px;
    padding: 0 var(--button-padding-left);
    line-height: 18px;

    > .ButtonContainer {
      column-gap: var(--icon-gap);
    }

    .ButtonIcon {
      width: var(--icon-width);
      height: var(--icon-width);
      border-radius: calc(var(--icon-width) / 2);
      margin-right: calc(((var(--icon-width) - var(--icon-gap)) - var(--button-padding-left)) * -1);
    }
  }

  &.XS {
    --button-padding-left: 12px;
    --icon-width: 20px;
    --icon-gap: 6px;
    --icon-side-margin: 4px;

    @include heightAndBorderRadius(20px);
    font-size: 12px;
    padding: 0 var(--button-padding-left);
    line-height: 16px;

    > .ButtonContainer {
      column-gap: var(--icon-gap);
    }

    .ButtonIcon {
      width: var(--icon-width);
      height: var(--icon-width);
      border-radius: calc(var(--icon-width) / 2);
      margin-right: calc(((var(--icon-width) - var(--icon-gap)) - var(--button-padding-left)) * -1);
    }
  }
}

.secondary-dark {
  position: relative;
  color: colors.$neutral800;

  .ButtonIcon {
    position: relative;
    color: colors.$white;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: colors.$purple500;
      border-radius: inherit;
      z-index: -1;
    }
  }
}

.secondary-light {
  color: colors.$white;
  position: relative;

  .ButtonIcon {
    position: relative;
    color: colors.$purple500;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: colors.$white;
      border-radius: inherit;
      z-index: -1;
    }
  }
}

.secondary {
  z-index: 0;
  position: relative;
}

@media (hover: hover) {
  .secondary {
    padding-right: calc(var(--icon-gap) + var(--icon-width)) !important; // gap + iconWidth
    transition:
      color 225ms ease-in-out 100ms,
      transform 225ms ease-in-out 100ms;

    &-light {
      &:hover {
        color: colors.$purple500;
      }
      &:active {
        .ButtonIcon {
          outline-color: colors.$neutral800;
        }
      }
    }
    &-dark {
      &:hover {
        color: colors.$white;
      }
      &:active {
        .ButtonIcon {
          outline-color: colors.$white;
        }
      }
    }

    &:hover {
      transform: translate(12px, 0);

      .ButtonIcon {
        --half-icon-gap: calc((var(--icon-gap) / 2) * -1);
        width: calc(
          100% + var(--button-padding-left) + var(--icon-gap) + var(--icon-width)
        ); // widthOfButton + paddingLeft + gap + iconWidth
        transition-timing-function: ease-out;
        transition-delay: 100ms;
      }
    }
    &:active {
      .ButtonIcon {
        width: calc(
          100% + var(--button-padding-left) + calc(var(--icon-gap) / 2) + var(--icon-width)
        ); // widthOfButton + paddingLeft + gap + iconWidth
        transform: translate(var(--half-icon-gap), 0);
      }
    }

    .ButtonIcon {
      position: absolute;
      right: calc((var(--icon-width) + var(--button-border-width)) * -1); // -1 x iconWidth - borderWidth
      z-index: -1;
      justify-content: flex-end;
      outline: 2px solid transparent;
      outline-offset: -1px;
      transition:
        width 225ms cubic-bezier(0.27, 0.66, 0.8, 1.11) 150ms,
        outline-color 225ms ease-out,
        transform 225ms ease-out;
    }

    .Icon {
      margin: 0 var(--icon-side-margin);
    }
  }
}

.fullWidth {
  width: 100%;

  > .ButtonContainer {
    width: inherit;
  }
}

.cta-dark,
.cta-light {
  border: none;

  &:empty {
    overflow: hidden;
    border: none;
    padding: 0 !important;
    background-color: transparent;
    pointer-events: none;
    @include values.buttonSkeleton;
  }

  &:disabled {
    pointer-events: none;
  }

  > .ButtonContainer {
    column-gap: 8px;
  }

  .ButtonLoading {
    scale: 0.6;
  }

  &.M,
  &.L {
    @include heightAndBorderRadius(42px);
    padding: 0 20px;
  }

  &.S,
  &.XS {
    @include heightAndBorderRadius(34px);
    padding: 0 16px;
  }
}

.cta-dark {
  color: colors.$white;
  background-color: colors.$purple500;

  &:hover {
    color: colors.$white;
    background-color: colors.$purple600;
  }
}

.cta-light {
  color: colors.$purple600;
  background-color: colors.$purple300;

  &:hover {
    color: colors.$purple700;
    background-color: colors.$purple400;
  }
}

.loading {
  pointer-events: none;
  cursor: progress;
}
