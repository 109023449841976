@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use '$styles/mediaqueries';

$dotWidth: 5px;
$dotSpacing: 3px;

@function getLoaderSize($numberOfDots, $dotWidth, $dotSpacing) {
  @return ($dotWidth * $numberOfDots) + ($dotSpacing * ($numberOfDots - 1));
}

.LoadingIndicator {
    $size: getLoaderSize(3, $dotWidth, $dotSpacing)px;
    display: inline-block;
    width: $size;
    height: size;
}

@keyframes dotFlashAnimation {
    0% {
        background-color: transparent;
      }
      50%,
      100% {
        background-color: currentColor;
      }
}

.DotsFlashing {
  display: inline-block;
  position: relative;
  width: $dotWidth;
  height: $dotWidth;
  border-radius: calc($dotWidth/2);
  animation: dotFlashAnimation 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -8px;
    width: $dotWidth;
    height: $dotWidth;
    border-radius: $dotWidth;
    animation: dotFlashAnimation 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 8px;
    width: $dotWidth;
    height: $dotWidth;
    border-radius: $dotWidth;
    animation: dotFlashAnimation 1s infinite alternate;
    animation-delay: 1s;
  }
}