@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/variables/radii';
@use '$styles/mediaqueries';
@use '$styles/variables/elevation';
@use '$styles/variables/spacings';

.component {
  box-sizing: border-box;
  position: fixed;

  &:global(.modal-drawer) {
    height: calc(100vh - 56px);
    width: 100vw;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    @supports (height: 100dvh) {
      height: calc(100dvh - 56px);
    }

    @include mediaqueries.smallUp {
      width: fit-content;
      min-width: 416px;
      height: calc(100vh - 48px);
      left: auto;
      top: spacings.$M;
      right: 0;
      bottom: spacings.$M;
      padding-right: spacings.$M;

      filter: drop-shadow(0 4px 20px rgba(colors.$neutral800, 0.15))
        drop-shadow(0 0 3px rgba(colors.$neutral800, 0.1));
    }

    > .modalContainer {
      top: 8px;
      padding: 20px 0;
      border-radius: 16px 16px 0 0;
      transform-origin: top center;

      @include mediaqueries.smallUp {
        padding: spacings.$S spacings.$M;
        top: 0;
        height: 100%;
        border-radius: radii.$M;
        transform: translateX(calc(100% + 24px + 5px)); // additional 5px covers the shadow spread
      }

      &.open {
        transform: translateY(0);

        @include mediaqueries.smallUp {
          transform: translateX(0);
        }
      }
    }

    .closeButton {
      right: spacings.$L;
      top: spacings.$M;
    }

    .mobileSpacing {
      @include mediaqueries.extraSmallOnly {
        height: 50px;
      }
    }
  }

  &:global(.modal) {
    min-height: 432px;
    width: 320px;
    top: 50%;
    left: 50%;
    translate: -50% -50%;

    overflow: hidden;

    @include mediaqueries.smallUp {
      min-width: 562px;
      min-height: 352px;

      filter: drop-shadow(0 4px 20px rgba(colors.$neutral800, 0.15))
        drop-shadow(0 0 3px rgba(colors.$neutral800, 0.1));
    }

    > .modalContainer {
      border-radius: radii.$L;
      padding: spacings.$L spacings.$S;
      transform-origin: center;
      transform: scale(0);
      transition-duration: 200ms;

      @include mediaqueries.smallUp {
        padding: spacings.$L;
      }
      &.open {
        transform: scale(1);
      }
    }

    .closeButton {
      top: spacings.$M;
      right: spacings.$S;

      @include mediaqueries.smallUp {
        top: spacings.$L;
        right: spacings.$L;
      }
    }
  }
  &:global(.video-modal) {
    width: 100vw;
    top: 50%;
    left: 50%;
    translate: -50% -50%;

    @include mediaqueries.smallUp {
      width: calc(100vw - (2 * spacings.$L) - 1px);
      filter: drop-shadow(0 4px 20px rgba(colors.$neutral800, 0.15))
        drop-shadow(0 0 3px rgba(colors.$neutral800, 0.1));
    }
    @include mediaqueries.mediumUp {
      width: calc(100vw - (2 * spacings.$XL) - 1px);
    }
    @include mediaqueries.largeUp {
      width: calc(100vw - (2 * spacings.$XXL) - 1px);
    }
    > .modalContainer {
      @include mediaqueries.smallUp {
        border-radius: radii.$XS;
      }
      @include mediaqueries.largeUp {
        border-radius: radii.$S;
      }
      padding: 0;
      transform-origin: center;
      transform: scale(0);
      transition-duration: 200ms;
      aspect-ratio: 16/8;
      &.open {
        transform: scale(1);
      }
    }
    .contentContainer {
      padding-right: 0;
    }

    .closeButton {
      top: -(spacings.$XL);
      right: 0;
      svg {
        color: colors.$white;
      }
      @include mediaqueries.upToMedium {
        svg {
          transform: scale(0.75);
        }
      }
      @include mediaqueries.largeUp {
        top: -(spacings.$XXL);
      }
    }
  }
}

.modalContainer {
  height: 100%;
  position: relative;
  background-color: colors.$white;
  box-shadow:
    0px 0px 2px 0px rgba(0, 0, 0, 0.25),
    0px 4px 7px 0px rgba(0, 0, 0, 0.09);
  transform: translateY(100%);

  transition: transform 400ms ease-in;

  @include mediaqueries.smallUp {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: inherit;
      width: 100%;
      height: 100%;
      background-color: transparentize($color: colors.$neutral800, $amount: 0.5);
      opacity: 0;
      transform: scale(0);
      transition:
        opacity 400ms ease,
        transform 0s ease 400ms;
    }
  }

  &.behind {
    @include mediaqueries.upToSmall {
      transform: translateY(-8px) scale(0.95);
    }

    @include mediaqueries.smallUp {
      &::after {
        transition:
          opacity 400ms ease,
          transform 0s ease 0s;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

.contentContainer {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 30px;
  @include mediaqueries.smallUp {
    margin-top: 0;
    padding-right: 10px;
  }
}

.closeButton {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  color: black;
  font-size: 0;
  position: absolute;
  z-index: elevation.$modals;
  cursor: pointer;
}
